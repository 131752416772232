.style1 {
	font-family: "Courier New", Courier, mono;
	font-size: 12px;
	font-weight: bold;
}
.style2 {
	font-family: "Courier New", Courier, mono;
	font-size: 12px;
	font-weight: bold; 
}
.style3 {font-family: "Courier New", Courier, mono; font-size: 12px; font-weight: bold;}
.style4 {font-family: "Courier New", Courier, mono; font-size: 12px; font-weight: bold; }
hr { border-top: 1px solid black; }
@media print {
    .page, .page-break { break-after: page; }
}